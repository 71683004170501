import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthTokensInterceptor } from './_interceptors/auth-tokens.interceptor';
import { BaseUrlInterceptor } from './_interceptors/base-url.interceptor';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import AppErrorHandler from './app-error-handler';
import { UserOnBehalfOfInterceptor } from './_interceptors/user-onbehalf-of.interceptor';

const disableAnimations =
  !('animate' in document.documentElement)
  || (navigator && /iPhone OS (8|9|10|11|12|13)_/.test(navigator.userAgent));

@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule.withConfig({ disableAnimations })], providers: [
        {
            provide: ErrorHandler,
            useValue: new AppErrorHandler({
                showDialog: false
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthTokensInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: UserOnBehalfOfInterceptor, multi: true },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule { }
